// Copyright (C) 2019 TANNER AG

import React from "react";
import Container from "@c42-ui/core/container";
import OmniboxInput from "@c42-ui/core/omniboxinput";
import OmniboxAction from "@c42-ui/core/omniboxaction";
import SearchIcon from "@material-ui/icons/Search";
import useTranslation from "@c42-ui/core/usetranslation";
import { useC42 } from "@c42-ui/core";
import Box from "@material-ui/core/Box";
import useAutoSuggest from "@c42-ui/core/useautosuggest";
import { createStyles, fade, makeStyles, Theme } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import { useHistory } from "react-router-dom";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { Option } from "@c42-ui/core/types";
import Grid from "@material-ui/core/Grid";
import HomeItem from "./item";
import useSearch from "@c42-ui/core/usesearch";
import settings from "../../settings.json"

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: "flex",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				alignItems: "flex-start"
			}
		},
		searchWrapper: {
			maxWidth: 600
		},
		searchContainer: {
			zIndex: 1,
			borderRadius: 2,
			backgroundColor: fade(theme.palette.text.primary, 0.05),
			boxShadow: "none"
		},
		focusedSearchContainer: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[1]
		},
		content: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginTop: 50,
			[theme.breakpoints.down("sm")]: {
				marginTop: 0
			}
		},
		logo: {
			width: "100%"
		}
	})
);

const Home = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { fetchAutoSuggest } = useAutoSuggest();
	const { push } = useHistory();
	const { generateLink } = useLinkHandler();
	const { setup: { app: { basename, logo, name } } } = useC42();
	const { open, setQuery} = useSearch();

	const onSelection = (option: Option) => {
		push(generateLink(option.target));
	};

	const onEnter = (value: string) => {
		setQuery(value);
		open();
	};

	return (
		<Box className={classes.wrapper}>
			<Container gutterTop className={classes.content}>
				<Box display="flex" justifyContent="center" mb={6} width="100%" maxWidth={300}>
					<img className={classes.logo} src={basename + logo} alt={name}/>
				</Box>
				<OmniboxInput
					className={classes.searchWrapper}
					classes={{
						container: classes.searchContainer,
						containerFocused: classes.focusedSearchContainer
					}}
					placeholder={t("search.product.placeholder")}
					fetchSuggestions={fetchAutoSuggest}
					onSuggestionSelected={onSelection}
					onEnter={onEnter}
					prefixActions={(
						<OmniboxAction title={t("search.title")}>
							<SearchIcon/>
						</OmniboxAction>
					)}
					suffixActions={(
						<OmniboxAction title={t("search.execute")}>
							<SendIcon/>
						</OmniboxAction>
					)}
				/>
				<Box width="100%" maxWidth={600} mt={4}>
					<Grid container spacing={2}>
						{settings.entryProducts.map(product => (
							<HomeItem key={product} productId={product}/>
						))}
					</Grid>
				</Box>
			</Container>
		</Box>
	);
};

export default Home;
