// Copyright (C) 2019 TANNER AG

import React, { useEffect } from "react";
import FilterRadio from "@c42-ui/core/filter/radio";
import FilterGroup from "@c42-ui/core/filter/group";
import RadioGroup from "@material-ui/core/RadioGroup";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useAiSearch from "@c42-ui/core/useaisearch";
import useAiSearchTouchpoints from "@c42-ui/core/useaisearchtouchpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { useHistory } from "react-router-dom";
import useSearch from "@c42-ui/core/usesearch";

const AiSearch: React.FC<{ executeFullTextSearch(): void }> = ({ executeFullTextSearch }) => {
	const { generateLink } = useLinkHandler();
	const { push } = useHistory();
	const { query, close, setQuery } = useSearch();
	const { intent } = useAiSearch(query);
	const { data, setFilter, loading } = useAiSearchTouchpoints(intent || "", query);

	useEffect(() => {
		if (data && data.count === 0) {
			executeFullTextSearch();
		}
	}, [data, executeFullTextSearch]);

	const handleMatch = () => {
		if (!data?.matches.length) {
			return;
		}

		const match = data?.matches[0];

		close();
		setQuery("");
		push(generateLink(match?.target));
	};

	return (
		<Box display="flex" justifyContent="center">
			{loading && (
				<Box mt={8}>
					<CircularProgress disableShrink/>
				</Box>
			)}
			{data && (
				<Box display="flex" flexDirection="column">
					{data?.filters.map(filter => (
						<FilterGroup key={filter.id} label="">
							<Typography variant="h5">{filter.name}</Typography>
							<RadioGroup onChange={(event: unknown, value: string) => setFilter(filter.id, value)}>
								{filter.values.map(value => (
									<FilterRadio
										key={value.value}
										label={value.label || value.value}
										value={value.value}
									/>
								))}
							</RadioGroup>
						</FilterGroup>
					))}
					<Box>
						<Button
							variant="outlined"
							style={{ marginRight: 8 }}
							color="primary"
							onClick={executeFullTextSearch}
						>
							Volltextsuche
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={data?.matches.length !== 1}
							onClick={handleMatch}
						>
							Inhalt öffnen
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default AiSearch;
