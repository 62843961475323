// Copyright (C) 2019 TANNER AG

import React from "react";
import FloatingBar from "@c42-ui/core/floatingbar";
import FloatingBarAction from "@c42-ui/core/floatingbaraction";
import { ActionTabIndex } from "../drawer/documentdrawer";
import useShift from "@c42-ui/core/useshift";
import EditIcon from "@material-ui/icons/Edit";

interface Props {
	setActiveIndex(index: ActionTabIndex): void;
}

const DocumentToolbar: React.FC<Props> = ({ setActiveIndex }) => {
	const { shiftRight: { setActive } } = useShift();

	const onClick = (index: ActionTabIndex) => {
		setActiveIndex(index);
		setActive(true);
	};

	return (
		<FloatingBar>
			<FloatingBarAction title="Metainfos" color="primary" onClick={() => onClick(0)}>
				<EditIcon/>
			</FloatingBarAction>
		</FloatingBar>
	);
};

export default DocumentToolbar;
