// Copyright (C) 2019 TANNER AG

import React, { useState } from "react";
import { TocItem } from "@c42-ui/core";
import useShift from "@c42-ui/core/useshift";
import C42DocumentToc, { DocumentTocItem, DocumentTocToggle, DocumentTocToolbar } from "@c42-ui/core/documenttoc";
import useTreeFilter from "@c42-ui/core/utils/usetreefilter";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import useTocScroller from "@c42-ui/core/usetocscroller/usetocscroller";

interface Props {
	items: TocItem[];
	loading?: boolean;
}

const DocumentToc: React.FC<Props> = ({ items, loading }) => {
	const { shiftLeft: { active, setActive, width } } = useShift();
	const [query, setQuery] = useState("");
	const { filteredItems } = useTreeFilter(items || [], query);

	useTocScroller({
		offset: 100,
		tocItems:filteredItems,
		update: true
	});

	return (
		<C42DocumentToc
			open={active}
			setOpen={setActive}
			width={width}
			toolbar={<DocumentTocToolbar query={query} setQuery={setQuery}/>}
			toggle={<DocumentTocToggle open={!active} onOpen={() => setActive(true)}/>}
		>
			{loading && (
				<Box display="flex" justifyContent="center" m={2}>
					<CircularProgress/>
				</Box>
			)}
			{filteredItems.map((tocItem: TocItem, index: number) => (
				<DocumentTocItem
					key={index}
					item={tocItem} depth={0}
				/>
			))}
		</C42DocumentToc>
	);
};

export default DocumentToc;
