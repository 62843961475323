// Copyright (C) 2020 TANNER AG

import React, { useState } from "react";
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
import { boschTheme, boschDarkTheme } from "../../themes/boschtheme";

export enum Themes {
	BOSCH = "bosch",
	BUDERUS = "buderus"
}

export interface CustomerThemeContextProps {
	theme?: ThemeOptions;
	darkTheme?: ThemeOptions;
	name: Themes;
	setThemes(theme: ThemeOptions, darkTheme: ThemeOptions, name: string): void;
}

const CustomerThemeContext = React.createContext<CustomerThemeContextProps>({
	setThemes: () => null,
	name: Themes.BOSCH
});

const CustomerThemeProvider: React.FC = ({ children }) => {
	const [theme, setTheme] = useState(boschTheme);
	const [darkTheme, setDarkTheme] = useState(boschDarkTheme);
	const [name, setName] = useState(Themes.BOSCH);

	const setThemes= (theme: ThemeOptions, darkTheme: ThemeOptions, name: Themes) => {
		console.log(darkTheme);
		setTheme(theme);
		setDarkTheme(darkTheme);
		setName(name);
	};

	return (
		<CustomerThemeContext.Provider value={{ name, theme, darkTheme, setThemes }}>
			{children}
		</CustomerThemeContext.Provider>
	);
};

export const useCustomerTheme = () => React.useContext(CustomerThemeContext);

export default CustomerThemeProvider;
