// Copyright (C) 2020 TANNER AG

import React from "react";
import C42SignIn from "@c42-ui/core/signin";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		logo: {
			height:60,
			maxHeight: 60
		}
	}),
);

const SignIn: React.FC<{ onSuccess?(): void }> = ({ onSuccess }) => {
	const classes = useStyles();

	return (
		<C42SignIn
			onSuccess={onSuccess}
			classes={classes}
			description=""
		/>
	);
};

export default SignIn;
