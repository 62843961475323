// Copyright (C) 2019 TANNER AG

import React from "react";
import ActionDrawer from "@c42-ui/core/actiondrawer";
import ActionDrawerHeader from "@c42-ui/core/actiondrawerheader";
import useShift from "@c42-ui/core/useshift";
import ActionTabs, { ActionTabPanel } from "@c42-ui/core/actiontabs";
import Tab from "@material-ui/core/Tab";
import useTranslation from "@c42-ui/core/usetranslation";
import EditIcon from "@material-ui/icons/Edit";
import Notes from "@c42-ui/core/notes";

export enum ActionTabIndex {
	Notes
}

interface Props {
	activeIndex: ActionTabIndex;
	setActiveIndex(index: ActionTabIndex): void;
	documentId: string;
}

const DocumentDrawer: React.FC<Props> = ({ activeIndex, setActiveIndex, documentId }) => {
	const { shiftRight: { active, setActive, width } } = useShift();
	const { t } = useTranslation();

	const titles: string[] = [];
	titles[ActionTabIndex.Notes] = t("notes.title");

	return (
		<ActionDrawer
			width={width}
			open={active}
			setOpen={setActive}
		>
			<ActionDrawerHeader
				setOpen={setActive}
				title={titles[activeIndex]}
			/>
			<ActionTabs
				activeIndex={activeIndex}
				setActiveTab={setActiveIndex}
				tabs={[
					<Tab
						key={ActionTabIndex.Notes}
						value={ActionTabIndex.Notes}
						label={t("notes.title")}
						icon={<EditIcon/>}
					/>
				]}
				variant="scrollable"
			>
				<ActionTabPanel
					value={activeIndex}
					index={ActionTabIndex.Notes}
				>
					<Notes showDate/>
				</ActionTabPanel>
			</ActionTabs>
		</ActionDrawer>
	);
};

export default DocumentDrawer;
