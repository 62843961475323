// Copyright (C) 2020 TANNER AG

import React from "react";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { SearchMatch } from "@c42-ui/core/types";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import { Link } from "react-router-dom";
import useSearch from "@c42-ui/core/usesearch";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			marginBottom: theme.spacing(3)
		}
	}),
);

const FullTextSearchItem: React.FC<SearchMatch> = ({ id, label, context }) => {
	const classes = useStyles();
	const { generateLink } = useLinkHandler();
	const { close } = useSearch();

	const documentId = id.replace("/", "@2F");
	const to = generateLink({
		toc: `/user/tocs/${documentId}`,
		content: `/user/content/documents/${documentId}/xhtml`,
		anchor: null
	});

	return (
		<Card className={classes.root}>
			<CardActionArea component={Link} to={to} onClick={close}>
				<CardContent>
					<Typography variant="h6" component="h3">
						{label}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p">
						[...]<span dangerouslySetInnerHTML={{ __html: context }}/>[...]
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

export default FullTextSearchItem;
