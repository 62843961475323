// Copyright (C) 2020 TANNER AG

import React from "react";
import useSearch from "@c42-ui/core/usesearch";
import FullTextSearchItem from "./item";

const FullTextSearch = () => {
	const { matches } = useSearch();

	return (
		<div>
			{matches.map(match => <FullTextSearchItem key={match.id} {...match}/>)}
		</div>
	);
};

export default FullTextSearch;
