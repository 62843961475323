// Copyright (C) 2019 TANNER AG

import React from "react";
import Setup from "./setup";
import Appbar from "./components/appbar";
import { Switch, Route, useHistory } from "react-router-dom";
import useAuth from "@c42-ui/core/useauth";
import SignIn from "./components/signin";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import Document from "./components/document";
import Product from "./components/product";
import ShiftProvider from "@c42-ui/core/shiftprovider";
import Admin from "./components/admin";
import Home from "./components/home";
import SearchProvider from "@c42-ui/core/searchprovider";

import "./styles/basic.scss"
import CustomerThemeProvider from "./components/customertheme";

const App: React.FC = () => {
	const { user } = useAuth();
	const { push, location } = useHistory();
	const { routes: { document, product, signin } } = useLinkHandler();

	if (!user && location.pathname !== signin) {
		push(signin);
		return null;
	}

	return (
		<SearchProvider>
			<ShiftProvider>
				<Route path={["/", document, product, `${product}/*`, "/admin", "/admin/*"]} exact>
					<Appbar/>
				</Route>
				<Switch>
					<Route path="/" exact>
						<Home/>
					</Route>
					<Route path={document}>
						<Document/>
					</Route>
					<Route path={product}>
						<Product/>
					</Route>
					<Route path={signin}>
						<SignIn onSuccess={() => push("/")}/>
					</Route>
					<Route path="/admin">
						<Admin/>
					</Route>
				</Switch>
			</ShiftProvider>
		</SearchProvider>
	);
};

const AppSetup: React.FC = () => (
	<CustomerThemeProvider>
		<Setup>
			<App/>
		</Setup>
	</CustomerThemeProvider>
);

export default AppSetup;
