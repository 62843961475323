// Copyright (C) 2019 TANNER AG

import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Box from "@material-ui/core/Box";
import OmniboxInput from "@c42-ui/core/omniboxinput";
import OmniboxAction from "@c42-ui/core/omniboxaction";
import Typography from "@material-ui/core/Typography";
import Container from "@c42-ui/core/container";
import AiSearch from "../aisearch";
import ActionDrawer from "@c42-ui/core/actiondrawer";
import ActionDrawerHeader from "@c42-ui/core/actiondrawerheader";
import useAutoSuggest from "@c42-ui/core/useautosuggest";
import { Option } from "@c42-ui/core/types";
import { useHistory } from "react-router-dom"
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTranslation from "@c42-ui/core/usetranslation";
import useSearch from "@c42-ui/core/usesearch";
import FullTextSearch from "../fulltextsearch";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			zIndex: 1201
		},
		toolbar: {
			justifyContent: "space-between"
		},
		title: {
			marginLeft: theme.spacing(2),
			alignItems: "center",
			display: "flex"
		},
		searchInput: {
			padding: "0 4px"
		},
		searchWrapper: {
			maxWidth: 600,
			width: "100%"
		}
	})
);

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const SearchDialog: React.FC = () => {
	const classes = useStyles();
	const { query, setQuery, close, isOpen } = useSearch();
	const { generateLink } = useLinkHandler();
	const [open, setOpen] = useState(false);
	const [fulltextSearch, setFullTextSearch] = useState(false);
	const { fetchAutoSuggest } = useAutoSuggest();
	const match = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
	const { push } = useHistory();
	const { t } = useTranslation();

	const onSelection = (option: Option) => {
		close();
		setQuery("");
		push(generateLink(option.target));
	};

	const onEnter = (value: string) => {
		setQuery(value);
		setFullTextSearch(false);
	};

	const executeFullTextSearch = () => setFullTextSearch(true);

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar className={classes.appBar} position="sticky">
				<Toolbar className={classes.toolbar}>
					<Box display="flex">
						<IconButton edge="start" color="inherit" onClick={close}>
							<CloseIcon/>
						</IconButton>
						{match && (
							<Typography variant="h6" className={classes.title}>
								Search
							</Typography>
						)}
					</Box>
					<Box className={classes.searchWrapper}>
						<OmniboxInput
							placeholder={t("search.placeholder")}
							classes={{ input: classes.searchInput }}
							fetchSuggestions={fetchAutoSuggest}
							onSuggestionSelected={onSelection}
							onEnter={onEnter}
							initialValue={query}
							prefixActions={(
								<OmniboxAction title="Search">
									<SearchIcon/>
								</OmniboxAction>
							)}
							suffixActions={(
								<OmniboxAction title="Filter" onClick={() => setOpen(!open)}>
									<TuneIcon/>
								</OmniboxAction>
							)}
						/>
					</Box>
					<IconButton/>
				</Toolbar>
			</AppBar>
			<Container gutterTop>
				{!fulltextSearch && <AiSearch executeFullTextSearch={executeFullTextSearch}/>}
				{fulltextSearch && <FullTextSearch/>}
			</Container>
			<ActionDrawer open={open} width={280}>
				<ActionDrawerHeader setOpen={setOpen} title="Filter"/>
			</ActionDrawer>
		</Dialog>
	);
};

export default SearchDialog;
