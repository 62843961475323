// Copyright (C) 2019 TANNER AG

import React from "react";
import { C42Setup, createC42Setup } from "@c42-ui/core/c42setup";
import C42Provider from "@c42-ui/core/c42provider";
import { BrowserRouter as Router } from "react-router-dom";
import TranslationProvider from "@c42-ui/core/translationprovider";
import { Language } from "@c42-ui/core/consts";
import { HtmlParser, LinkGenerator, LinkTarget } from "@c42-ui/core/types";
import { DataAttribute } from "@c42-ui/core/consts";
import { LinkHandlerContext } from "@c42-ui/core/usec42/c42context";
import { DomElement, domToReact } from "html-react-parser"
import { isTargetLink } from "@c42-ui/core/htmlparser/htmlparser";
import Link from "@c42-ui/core/link";
import { useCustomerTheme } from "./components/customertheme/customertheme";

// Custom link path generator by link target
const linkGenerator: LinkGenerator = (target: LinkTarget, setup: C42Setup): string => {
	if (target.type === "product") {
		return setup.routes.product.replace(":id", encodeURIComponent(target.content || ""))
	}

	let link = setup.routes.document.replace(":id", encodeURIComponent(target.toc || ""));

	if (target.anchor) {
		link += `#${target.anchor}`;
	}

	return link;
};

// Custom html parser
const htmlParser: HtmlParser = (element: DomElement, context: LinkHandlerContext) => {
	if (element.name === "a" && element.attribs && isTargetLink(element.attribs)) {
		const target: LinkTarget = {
			toc: element.attribs[DataAttribute.toc],
			content: element.attribs[DataAttribute.content],
			anchor: element.attribs[DataAttribute.anchor],
		};

		return (
			<Link to={context.generateLink(target)}>
				{element.children && domToReact(element.children)}
			</Link>
		);
	}

	if (element.name === "img" && element.attribs) {
		return <img {...element.attribs} src="/no_image.png"/>;
	}

	return false;
};

const Setup: React.FC = (props) => {
	const { theme: lightTheme, darkTheme } = useCustomerTheme();

	console.log(darkTheme);

	const setup = createC42Setup({
		services: {
			domain: process.env.REACT_APP_SERVICES_DOMAIN,
			prefix: process.env.REACT_APP_SERVCIES_PREFIX
		},
		app: {
			name: process.env.REACT_APP_NAME,
			linkGenerator,
			htmlParser,
			offset: 64
		},
		auth: true,
		theme: {
			osDetection: true,
			lightTheme,
			darkTheme
		}
	});

	return (
		<Router basename={setup.app.basename}>
			<C42Provider setup={setup}>
				<TranslationProvider
					defaultLanguage={Language.DE}
					languages={[Language.DE]}
				>
					{props.children}
				</TranslationProvider>
			</C42Provider>
		</Router>
	);
};

export default Setup;
