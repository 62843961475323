// Copyright (C) 2020 TANNER AG

import React from "react";
import NavigationDrawer from "@c42-ui/core/navigationdrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { Product } from "@c42-ui/core/types";
import { UserEndpoint } from "@c42-ui/core/consts";
import { LinkTarget } from "@c42-ui/core";
import Link from "@c42-ui/core/link";
import settings from "../../settings.json"

const NavigationItem: React.FC<{ productId: string, closeDrawer(): void }> = ({ productId, closeDrawer }) => {
	const { generateLink } = useLinkHandler();
	const { data, error } = useServiceFetch<Product>({
		url: UserEndpoint.Product,
		params: {
			productId
		}
	});

	if (error || !data) {
		return null;
	}

	const target: LinkTarget = {
		content: "/user/content/products/{productId}".replace("{productId}", productId),
		toc: null,
		anchor: null,
		type: "product"
	};

	return (
		<ListItem button component={Link} to={generateLink(target)} onClick={closeDrawer}>
			<ListItemAvatar>
				<Avatar
					src={data?.thumbnail || ""}
					alt={data?.name}
				/>
			</ListItemAvatar>
			<ListItemText
				primary={data?.name}
				secondary={data?.description}
			/>
		</ListItem>
	);
};

const Navigation: React.FC<{ open: boolean, setOpen: (open: boolean) => void }> = ({ open, setOpen }) => {
	return (
		<NavigationDrawer open={open} onClose={() => setOpen(false)} width={380}>
			<List>
				{settings.entryProducts.map(product => (
					<NavigationItem
						key={product}
						closeDrawer={() => setOpen(false)}
						productId={product}
					/>
				))}
			</List>
		</NavigationDrawer>
	);
};

export default Navigation;
