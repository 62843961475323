// Copyright (C) 2019 TANNER AG

import React, { useEffect, useState } from "react";
import DocumentContent from "./content";
import DocumentDrawer from "./drawer";
import DocumentToolbar from "./toolbar";
import { ActionTabIndex } from "./drawer/documentdrawer";
import { useParams } from "react-router";
import useDocumentToc from "@c42-ui/core/usedocumenttoc";
import DocumentToc from "./toc";
import ShiftProvider from "@c42-ui/core/shiftprovider";
import BackToTop from "../backtotop";
import { buderusDarkTheme, buderusTheme } from "../../themes/buderustheme";
import { Themes, useCustomerTheme } from "../customertheme/customertheme";
import { boschDarkTheme, boschTheme } from "../../themes/boschtheme";

const Document = () => {
	const [tabIndex, setTabIndex] = useState(ActionTabIndex.Notes);
	const { setThemes } = useCustomerTheme();
	const { id } = useParams();
	const documentUrl = decodeURIComponent(id || "");
	const documentId = documentUrl.substr(documentUrl.lastIndexOf("/") + 1);
	const { tocItems, fragments, loading } = useDocumentToc(documentUrl);

	console.log(documentUrl);

	useEffect(() => {
		if (documentUrl === "/user/tocs/c42-con-xml-de@2FOBJ_DOKU-6720892022-01.xml") {
			setThemes(buderusTheme, buderusDarkTheme, Themes.BUDERUS);
		}

		return () => {
			setThemes(boschTheme, boschDarkTheme, Themes.BOSCH);
		};
	});

	return (
		<ShiftProvider shiftLeftWidth={350} shiftRightWidth={550} shiftLeftOpen>
			<DocumentToc items={tocItems} loading={loading}/>
			<DocumentContent fragment={fragments[0] || ""}/>
			<DocumentDrawer activeIndex={tabIndex} setActiveIndex={setTabIndex} documentId={documentId}/>
			<DocumentToolbar setActiveIndex={setTabIndex}/>
			<BackToTop/>
		</ShiftProvider>
	);
};

export default Document;
