// Copyright (C) 2019 TANNER AG

import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AdminDashboard from "@c42-ui/admin/dashbaord";
import AdminDatabases from "@c42-ui/admin/databases";
import AdminDatabase from "@c42-ui/admin/database";
import AdminUsers from "@c42-ui/admin/users";
import AdminDocuments from "@c42-ui/admin/documents";
import Container from "@c42-ui/core/container";
import AdminCustomIndexes from "@c42-ui/admin/customindexes";
import AdminEditor from "@c42-ui/admin/editor";
import { parse } from "query-string"
import Box from "@material-ui/core/Box";

const Admin = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			<Route path={path} exact>
				<AdminDashboard/>
			</Route>
			<Route path={`${path}/databases`} exact>
				<AdminDatabases/>
			</Route>
			<Route
				path={`${path}/databases/:databaseId`}
				exact
				render={(props) => (
					<>
						<AdminDatabase databaseId={props.match.params.databaseId}/>
						<Container>
							<AdminDocuments databaseId={props.match.params.databaseId}/>
						</Container>
					</>
				)}
			/>
			<Route path={`${path}/users`} exact component={AdminUsers}/>
			<Route path={`${path}/custom-indexes`} exact component={AdminCustomIndexes}/>
			<Route
				path={`${path}/editor`}
				exact
				render={({ location: { search } }) => {
					const params = parse(search);

					return (
						<Box mx={4}>
							<AdminEditor
								url={params.url?.toString() || ""}
								title={params.title?.toString() || ""}
							/>
						</Box>
					)
				}}
			/>
		</Switch>
	);
};

export default Admin;
