// Copyright (C) 2019 TANNER AG

import React from "react";
import { LinkTarget } from "@c42-ui/core";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Link from "@c42-ui/core/link";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardMedia from "@material-ui/core/CardMedia";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import { Product } from "@c42-ui/core/types";
import Skeleton from "@material-ui/lab/Skeleton"
import { UserEndpoint } from "@c42-ui/core/consts";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			height: "100%"
		},
		actionArea: {
			height: "100%"
		},
		media: {
			height: 210
		}
	})
);

interface Props {
	productId: string;
}

const HomeItem: React.FC<Props> = ({ productId }) => {
	const classes = useStyles();
	const { generateLink } = useLinkHandler();
	const { data, error } = useServiceFetch<Product>({
		url: UserEndpoint.Product,
		params: {
			productId
		}
	});

	if (error) {
		return null;
	}

	const target: LinkTarget = {
		content: "/user/content/products/{productId}".replace("{productId}", productId),
		toc: null,
		anchor: null,
		type: "product"
	};

	return (
		<Grid item xs={6}>
			<Card className={classes.card}>
				<CardActionArea component={Link} to={generateLink(target)} className={classes.actionArea}>
					{data ? (
						<CardMedia
							className={classes.media}
							image={data?.thumbnail || ""}
						/>
					) : (
						<Skeleton variant="rect" height={210}/>
					)}
					<CardContent>
						{data ? (
							<>
								<Typography
									align="left"
									variant="subtitle2"
								>
									{data?.name}
								</Typography>
								<Typography
									align="left"
									variant="body2"
									color="textSecondary"
								>
									{data?.description}
								</Typography>
							</>
						) : (
							<>
								<Skeleton variant="text"/>
								<Skeleton variant="text"/>
							</>
						)}
					</CardContent>
				</CardActionArea>
			</Card>
		</Grid>
	);
};

export default HomeItem;
