// Copyright (C) 2019 TANNER AG

import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import DescriptionIcon from "@material-ui/icons/Description";
import { Product, Touchpoint } from "@c42-ui/core/types";
import Link from "@c42-ui/core/link";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useState } from "react";

interface TouchpointGroup {
	name: string;
	items: Touchpoint[];
}

const transformTouchpoints = (touchpoints: Touchpoint[]): TouchpointGroup[] => {
	const groupedTouchpoints: TouchpointGroup[] = [];

	touchpoints.forEach(touchpoint => {
		if (touchpoint.target.anchor !== " ") {
			if (!touchpoint.group) {
				groupedTouchpoints.push({
					name: "",
					items: [touchpoint]
				});
			} else {
				const group = groupedTouchpoints.find(group => group.name === touchpoint.group);
				if (group) {
					group.items.push(touchpoint);
				} else {
					groupedTouchpoints.push({
						name: touchpoint.group,
						items: [touchpoint]
					});
				}
			}
		}
	});

	return groupedTouchpoints;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		imageContainer: {
			textAlign: "center"
		},
		image: {
			maxWidth: "90%",
			height: "auto"
		},
		nested: {
			paddingLeft: theme.spacing(6),
		},
		heading: {
			marginBottom: theme.spacing(3)
		}
	})
);

const TouchpointListItem: React.FC<{ touchpoint: Touchpoint, className?: string }> = ({ touchpoint, className }) => {
	const { generateLink } = useLinkHandler();

	return (
		<ListItem
			key={touchpoint.id}
			button
			dense
			component={Link}
			to={generateLink(touchpoint.target)}
			className={className}
		>
			<ListItemIcon>
				<TouchAppIcon color="primary"/>
			</ListItemIcon>
			<ListItemText primary={touchpoint.name}/>
		</ListItem>
	);
};

const ListItemCollapse: React.FC<{ group: TouchpointGroup }> = ({ group: { name, items } }) => {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	return (
		<>
			<ListItem button dense onClick={() => setOpen(!open)}>
				<ListItemIcon>
					<TouchAppIcon color="primary"/>
				</ListItemIcon>
				<ListItemText primary={name}/>
				{open ? <ExpandLess/> : <ExpandMore/>}
			</ListItem>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{items.map(item => (
						<TouchpointListItem key={item.id} touchpoint={item} className={classes.nested}/>
					))}
				</List>
			</Collapse>
		</>
	);
};

const ProductContent: React.FC<Product> = ({ thumbnail, description, touchpoints, documents, metadata, name }) => {
	const classes = useStyles();
	const { generateLink } = useLinkHandler();

	const groupedTouchpoints = transformTouchpoints(touchpoints);

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Typography
					variant="subtitle1"
					component="h2"
					color="textPrimary"
					gutterBottom
				>
					{description}
				</Typography>
				<Typography
					variant="h3"
					component="h1"
					color="textPrimary"
					className={classes.heading}
					gutterBottom
				>
					{name}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={6} className={classes.imageContainer}>
				{thumbnail && (
					<img
						className={classes.image}
						alt={name}
						src={thumbnail}
					/>
				)}
			</Grid>
			<Grid item xs={12} sm={6}>
				<List subheader={<ListSubheader>Ihre Aufgaben</ListSubheader>}>
					{groupedTouchpoints.map(group => {
						if (group.name) {
							return <ListItemCollapse key={group.name} group={group}/>;
						}

						const touchpoint = group.items[0];
						return <TouchpointListItem key={touchpoint.id} touchpoint={touchpoint}/>;
					})}
				</List>
				<Box mt={3}>
					<List subheader={<ListSubheader>Dokumente</ListSubheader>}>
						{documents.map(document => (
							<ListItem
								button
								key={document.id}
								component={Link}
								to={generateLink(document.target)}
							>
								<ListItemIcon>
									<DescriptionIcon color="primary"/>
								</ListItemIcon>
								<ListItemText primary={document.name}/>
							</ListItem>
						))}
					</List>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ProductContent;
