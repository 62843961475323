// Copyright (C) 2019 TANNER AG

import React from "react";
import useHTMLParser from "@c42-ui/core/usehtmlparser";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import Container from "@c42-ui/core/container";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import useDesign from "./design";
import useHashScroll from "@c42-ui/core/utils/usehashscroll";

interface Props {
	fragment: string;
}

const DocumentContent: React.FC<Props> = React.memo<Props>(({ fragment }) => {
	useDesign();
	const { parseHTML } = useHTMLParser();
	const { data } = useServiceFetch<string>({
		url: fragment,
		isText: true,
		isLazy: !Boolean(fragment)
	});

	useHashScroll();

	return (
		<Container>
			{!data && (
				<Box display="flex" justifyContent="center" mt={8}>
					<CircularProgress/>
				</Box>
			)}
			<div className="content">
				{parseHTML(data)}
			</div>
		</Container>
	);
});

export default DocumentContent;
