// Copyright (C) 2019 TANNER AG

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useDesign = makeStyles((theme: Theme) =>
	createStyles({
		"@global": {
			".content": {
				fontSize: "1rem",
				lineHeight: "1.5",

				// Headings
				"& h1": theme.typography.h1,
				"& h2": theme.typography.h2,
				"& h3": theme.typography.h3,
				"& h4": theme.typography.h4,
				"& h5": theme.typography.h5,

				"& table": {
					maxWidth: "100%",
					width: "100%",
					display: "table",
					borderSpacing: 0,
					borderCollapse: "collapse",
					marginBottom: theme.spacing(2),
					fontSize: "0.875rem",

					"& thead": {
						display: "table-header-group",

						"& tr": {
							background: theme.palette.grey["200"],

							"& td, & th": {

							}
						}
					},

					"& tbody": {
						display: "table-row-group",
					},

					"& tr": {
						color: "inherit",
						display: "table-row",
						outline: 0,
						verticalAlign: "middle",

						"&:nth-of-type(odd)": {
							backgroundColor: theme.palette.grey["100"]
						}
					},

					"& td, & th": {
						display: "table-cell",
						padding: theme.spacing(1, 0.5),
						textAlign: "left",
						letterSpacing: "0.01071em",
						verticalAlign: "inherit",
						border: "1px solid rgba(224, 224, 224, 1)",

						"& > p": {
							margin: 0
						},

						"& img": {
							width: 100
						}
					},

					"& td > p": {
						whiteSpace: "nowrap"
					},

					"& th": {
						background: theme.palette.grey["200"],
					}
				},

				"& .figtitle, & .tabtitle": {
					color: theme.palette.grey["700"],
					fontStyle: "italic",
					marginTop: 0
				},

				"& .warning, & .remark": {
					borderRadius: 4,
					padding: theme.spacing(2),
					marginBottom: theme.spacing(2),
					width: "100%",
					position: "relative",
					paddingLeft: theme.spacing(7),

					"& > *:first-child": {
						marginTop: 0,
						paddingTop: 0
					},

					"& > *:last-child": {
						marginBottom: 0,
						paddingBottom: 0
					},

					"&::before": {
						display: "block",
						position: "absolute",
						top: theme.spacing(2),
						left: theme.spacing(2),
						width: 25,
						height: 25
					},

					"&.warning": {
						background: "#fff4e5",
						color: "#663c00",

						"&::before": {
							content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff9800' version='1.0' focusable='false' viewBox='0 0 24 24'%3E%3Cpath d='M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z'%3E%3C/path%3E%3C/svg%3E")`
						},

						"& .w-title": {
							...theme.typography.h4,
							fontWeight: theme.typography.fontWeightBold
						},

						"& .typeofhazard": {

						}
					},

					"&.remark": {
						background: "#e8f4fd",
						color: "#0d3c61",

						"&::before": {
							content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%232196f3' version='1.0' focusable='false' viewBox='0 0 24 24'%3E%3Cpath d='M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z'%3E%3C/path%3E%3C/svg%3E")`
						}
					}
				},

				// Lists
				"& ul": {
					listStyleType: "none",
					listStylePosition: "outside",
					marginLeft: 0,
					paddingLeft: 24,

					"& > li": {
						listStyle: "none",
						padding: 0,
						position: "relative",
						paddingBottom: theme.spacing(1),

						"&::before": {
							content: "''",
							display: "block",
							width: 6,
							height: 6,
							background: theme.palette.text.primary,
							position: "absolute",
							left: -23.4,
							top: "0.51em"
						},

						"& > p:first-child": {
							margin: 0
						}
					}
				},

				"& .legend > ul": {
					paddingLeft: 0,

					"& > li": {
						color: theme.palette.grey["700"],

						"&::before": {
							content: "unset"
						}
					}
				},

				"& .tooltip-footnote": {
					position: "relative",
					cursor: "pointer",
					fontWeight: theme.typography.fontWeightRegular,
					textDecoration: "underline",
					fontSize: "0.7rem",
					display: "inline-block",

					"& .tooltiptext": {
						display: "none",
						position: "absolute",
						cursor: "auto",
						padding: theme.spacing(1),
						textDecoration: "none",
						minWidth: 250,
						boxShadow: theme.shadows[2],
						backgroundColor: theme.palette.background.paper,
						zIndex: 1
					},

					"&:hover .tooltiptext": {
						display: "block"
					}
				},

				"& dl": {
					"& dt": {
						fontWeight: theme.typography.fontWeightBold
					},
					"& dd": {
						margin: 0,
						marginBottom: theme.spacing(1)
					}
				},

				"& .goal": {
					fontWeight: theme.typography.fontWeightBold
				}
			}
		}
	})
);

export default useDesign;
