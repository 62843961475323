// Copyright (C) 2019 TANNER AG

import React, { useEffect } from "react";
import Container from "@c42-ui/core/container";
import { MetaValue, Product as ProductSchema } from "@c42-ui/core/types";
import { useParams } from "react-router";
import useServiceFetch from "@c42-ui/core/utils/useservicefetch";
import ProductContent from "./content";
import { Themes, useCustomerTheme } from "../customertheme/customertheme";
import { buderusDarkTheme, buderusTheme } from "../../themes/buderustheme";
import { boschDarkTheme, boschTheme } from "../../themes/boschtheme";

// TODO add to core module as helper function
export const findConfigMeta = (metadata: MetaValue[], name: string): string => {
	const data = metadata.find(item => item.name === name);

	if (!data) {
		return `<${name}>`;
	}

	return data.value;
};

const Product = () => {
	const { id } = useParams();
	const { setThemes } = useCustomerTheme();
	const productId = decodeURIComponent(id || "");
	const { data } = useServiceFetch<ProductSchema>({ url: productId });

	useEffect(() => {
		if (productId === "/user/content/products/c42-con-xml-de@2FOBJ_DOKU-6720892022-01.xml") {
			console.log(buderusDarkTheme);
			setThemes(buderusTheme, buderusDarkTheme, Themes.BUDERUS);
		}

		return () => {
			setThemes(boschTheme, boschDarkTheme, Themes.BOSCH);
		};
	});

	if (!data) {
		return null;
	}

	return (
		<Container gutterTop>
			<ProductContent {...data}/>
		</Container>
	);
};

export default Product;
