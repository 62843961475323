// Copyright (C) 2019 TANNER AG

import React, { useState } from "react";
import AppBarAction from "@c42-ui/core/appbaraction";
import SearchIcon from "@material-ui/icons/Search";
import AuthToolbar from "@c42-ui/core/authtoolbar";
import SearchDialog from "../search";
import { Link, useHistory } from "react-router-dom";
import useTranslation from "@c42-ui/core/usetranslation";
import useSearch from "@c42-ui/core/usesearch";
import Navigation from "../navigation";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MaterialAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import useLinkHandler from "@c42-ui/core/uselinkhandler";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import useC42Theme from "@c42-ui/core/usec42theme";
import { Themes, useCustomerTheme } from "../customertheme/customertheme";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
		},
		appBarPaper: {
			background: theme.palette.background.paper,
			color: theme.palette.text.secondary
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		content: {
			display: "flex",
			flex: "1 1 auto"
		},
		logoLink: {
			display: "block",
			height: 40,
			marginRight: theme.spacing(3)
		},
		logo: {
			height: "100%"
		},
		buderus: {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,

			"& $logoLink": {
				height: 20
			}
		},
		offset: theme.mixins.toolbar
	})
);

const isBuderus = (theme: Themes) => theme === Themes.BUDERUS;

const AppBar = () => {
	const { name } = useCustomerTheme();
	const classes = useStyles();
	const { darkMode, setDarkMode } = useC42Theme();
	const { open, isOpen } = useSearch();
	const { push } = useHistory();
	const { t } = useTranslation();
	const { basename } = useLinkHandler();
	const [navOpen, setNavOpen] = useState(false);

	return (
		<>
			<MaterialAppBar className={clsx(classes.appBar, classes.appBarPaper, { [classes.buderus]: isBuderus(name) })}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						onClick={() => setNavOpen(true)}
					>
						<MenuIcon/>
					</IconButton>
					<div className={classes.content}>
						<Link to="/" className={classes.logoLink}>
							<img
								alt="Logo"
								src={`${basename}/${name}_logo.svg`}
								className={classes.logo}
							/>
						</Link>
						<Typography variant="h6" color="inherit"/>
					</div>
					<AppBarAction
						title={darkMode ? t("theme.light") : t("dark.theme")}
						onClick={() => setDarkMode(!darkMode)}
					>
						{darkMode ? <Brightness7Icon/> : <Brightness4Icon/>}
					</AppBarAction>
					<AppBarAction
						title={t("search.title")}
						disabled={isOpen}
						onClick={open}
					>
						<SearchIcon/>
					</AppBarAction>
					<AuthToolbar
						edge="end"
						onAdminClick={() => push("/admin")}
					/>
				</Toolbar>
			</MaterialAppBar>
			<Toolbar id="back-to-top-anchor" />
			<SearchDialog/>
			<Navigation open={navOpen} setOpen={setNavOpen}/>
		</>
	);
};

export default AppBar;
